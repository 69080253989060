<template>
  <v-data-table
    id="content"
    :headers="headers"
    :items="recommendationsAdmin"
    :search="search"
    sort-by="manufacturerName"
    :loading="loading"
    :loading-text="$t('admin.recommendations.loadingWait')"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title
          ><h2>{{ $t("admin.recommendations.title") }}</h2></v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('admin.recommendations.search')"
          single-line
          hide-details
          style="max-width: 300px;"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
          text
          icon
          color="primary"
          :disabled="loading"
          @click="showSettings()"
        >
          <v-icon>mdi-cog-outline</v-icon>
        </v-btn>
        <v-btn text icon color="primary" :disabled="loading" @click="saveRecommendationDownloads()">
          <v-icon>mdi-cloud-download-outline</v-icon>
        </v-btn>
        <v-btn text icon color="primary" :disabled="loading" @click="deleteRecommendationDownloads()">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <DataTableSettings
          :items="dataTableItems"
          @updateSettings="updateSettings($event)"
          :key="dataTableSettingsKey"
        />
      </v-toolbar>
    </template>
    <template v-slot:no-data>
      <span>{{ $t("admin.recommendations.noRecordsFound") }}</span>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";
import router from "@/router";

import DataTableSettings from "@/components/common/DataTableSettings";

import { api } from "@/helpers/ApiCalls";

export default {
  name: "RecommendationsAdmin",

  components: {
    DataTableSettings,
  },

  data() {
    return {
      headersList: [
        {
          text: this.$t("admin.recommendations.uomLabel"),
          value: "uom",
        },
        {
          text: this.$t("admin.recommendations.manufacturerNameLabel"),
          value: "manufacturerName",
        },
        {
          text: this.$t("admin.recommendations.modelLabel"),
          value: "model",
        },
        {
          text: this.$t("admin.recommendations.designationLabel"),
          value: "designation",
        },
        {
          text: this.$t("admin.recommendations.hardnessLabel"),
          value: "hardness",
        },
        {
          text: this.$t("admin.recommendations.dimensionsLabel"),
          value: "dimensions",
        },
        {
          text: this.$t("admin.recommendations.quantityLabel"),
          value: "quantity",
        },
        {
          text: this.$t("admin.recommendations.arrangementLabel"),
          value: "arrangement",
        },
        {
          text: this.$t("admin.recommendations.lubricationLabel"),
          value: "lubrication",
        },
        {
          text: this.$t("admin.recommendations.pinchingLabel"),
          value: "pinching",
        },
        {
          text: this.$t("admin.recommendations.bladeLabel"),
          value: "blade",
        },
        {
          text: this.$t("admin.recommendations.specsImperialLabel"),
          value: "specsImperial",
        },
        {
          text: this.$t("admin.recommendations.specsMetricLabel"),
          value: "specsMetric",
        },
        {
          text: this.$t("admin.recommendations.bandSpeedImperialLabel"),
          value: "bandSpeedImperial",
        },
        {
          text: this.$t("admin.recommendations.bandSpeedMetricLabel"),
          value: "bandSpeedMetric",
        },
        {
          text: this.$t("admin.recommendations.feedRateImperialLabel"),
          value: "feedRateImperial",
        },
        {
          text: this.$t("admin.recommendations.feedRateMetricLabel"),
          value: "feedRateMetric",
        },
        {
          text: this.$t("admin.recommendations.chipLoadImperialLabel"),
          value: "chipLoadImperial",
        },
        {
          text: this.$t("admin.recommendations.chipLoadMetricLabel"),
          value: "chipLoadMetric",
        },
        {
          text: this.$t("admin.recommendations.cuttingRateImperialLabel"),
          value: "cuttingRateImperial",
        },
        {
          text: this.$t("admin.recommendations.cuttingRateMetricLabel"),
          value: "cuttingRateMetric",
        },
        {
          text: this.$t("admin.recommendations.cutTimeLabel"),
          value: "cutTime",
        },
        {
          text: this.$t("admin.recommendations.createdDateLabel"),
          value: "createdDate",
        },
      ],
      headers: [],
      search: "",
      loading: false,
      dataTableItems: [],
    };
  },

  computed: {
    ...mapGetters([
      "recommendationsAdmin",
      "recommendationsAdminSettings",
      "dataTableSettingsKey",
    ]),
  },

  methods: {
    getAdminRecommendations(showNotification) {
      this.loading = true;

      this.$store.dispatch("getAdminRecommendations").then(
        (response) => {
          this.loading = false;

          if (showNotification) {
            const notification = {
              show: true,
              result: true,
              message: response.message,
            };

            this.$store.dispatch("showSnackbar", notification);

            this.updateHeaders();
          }
        },
        (error) => {
          this.loading = false;

          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);

          if (api.authenticationError(error.code)) {
            setTimeout(function () {
              router.push({ name: "Home" }).catch(() => {});
            }, api.authErrorRedirectTimeout);
          }
        }
      );
    },
    showSettings() {
      this.updateDataTableItems();
      this.$store.dispatch("showDataTableSettingsDialog", true);
    },
    updateSettings(params) {
      this.$store.dispatch("saveAdminRecommendationsSettings", params);

      this.updateHeadersFromSettings(params.headers);
    },
    updateHeaders() {
      if (this.settings()) {
        this.updateHeadersFromSettings(
          this.recommendationsAdminSettings.headers
        );
      } else {
        this.headers = this.headersList;
      }
    },
    updateHeadersFromSettings(headers) {
      var values = headers
        .filter((item) => item.selected)
        .map((item) => {
          return item.value;
        });

      this.headers = this.headersList.filter((item) =>
        values.includes(item.value)
      );
    },
    updateDataTableItems() {
      if (this.settings()) {
        this.dataTableItems = this.recommendationsAdminSettings.headers;
      } else {
        let items = this.headersList.map((o) => ({
          text: o.text + " (" + o.value + ")",
          value: o.value,
          selected: true,
        }));

        items.sort(function (a, b) {
          if (!a.text || !b.text) {
            return 0;
          }

          var x = a.text.toLowerCase();
          var y = b.text.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });

        this.dataTableItems = items;
      }

      this.$store.dispatch("reloadAdminRecommendationsSettingsComponent");
    },
    settings() {
      return (
        this.recommendationsAdminSettings &&
        this.recommendationsAdminSettings.headers != null
      );
    },
    saveRecommendationDownloads() {
      this.loading = true;

      this.$store.dispatch("saveRecommendationDownloads").then(
        (response) => {
          this.loading = false;
          const notification = {
            show: true,
            result: true,
            message: response.message,
            delay: 8000,
          };

          this.$store.dispatch("showSnackbar", notification);
        },
        (error) => {
          this.loading = false;

          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    deleteRecommendationDownloads() {
      const action = this.$t("admin.recommendations.deleteRecords");
      if (!confirm(action)) {
        return;
      }

      this.itemOverlay = true;

      this.$store.dispatch("deleteRecommendationDownloads").then(
        (response) => {
          this.itemOverlay = false;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);
          this.getAdminRecommendations(false);
        },
        (error) => {
          this.itemOverlay = false;

          const notification = {
            show: true,
            result: false,
            message: error.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
  },

  mounted() {
    this.getAdminRecommendations(true);
  },
};
</script>
